import { useRef, useState } from "react"
import styled from "styled-components"

import { Link } from "@material-ui/core"
import { AxiosError } from "axios"

import {
  GuestCSVErrors,
  usePostGuestsCSV,
} from "src/data/integrations/queries/integrationQueries"
import {
  TPostGuestsCSVAuthError,
  TPostGuestsCSVResponse,
  TPostGuestsCSVRowError,
} from "src/data/integrations/types/integrationTypes"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { MButtonLegacy } from "src/ui/Button/MButtonLegacy"
import { greyScale, mColors } from "src/ui/colors"
import FileUpload from "src/ui/icons/file-upload.svg"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

// Add classname so we can style this component using styled-components
export function DragDropArea({
  className,
  setUploadStatus,
}: {
  className?: string
  setUploadStatus: (status: UploadStatus | undefined) => void
}) {
  const [message, setFileMessage] = useState<string>()
  const [file, setFilePicked] = useState<File>()
  const inputFile = useRef<HTMLInputElement>(null)
  const { org } = useOrganization()
  const postGuestsCSV = usePostGuestsCSV()

  function dragTargetForFiles(event: React.DragEvent) {
    // Files are only valid drag and drop items
    if (event.dataTransfer.types.includes("Files")) {
      event.preventDefault()
      event.stopPropagation()
    }
  }

  function handleDrop(event: React.DragEvent) {
    event.preventDefault()
    event.stopPropagation()
    setUploadStatus(undefined)
    handleFile(event.dataTransfer.files, setFileMessage, setFilePicked)
  }

  function handleOnChange(event: React.ChangeEvent<HTMLInputElement>) {
    event.preventDefault()
    event.stopPropagation()
    setUploadStatus(undefined)
    handleFile(event.target.files, setFileMessage, setFilePicked)
  }

  function handleOnClick(
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) {
    event.stopPropagation()
    if (file) {
      postGuestsCSV.mutate(
        { orgId: org.id, file },
        {
          onSettled: (data, error) => {
            if (error && GuestCSVErrors.isRowErrors(error)) {
              setUploadStatus({ rowErrors: error.response?.data.errors })
            } else if (error && GuestCSVErrors.isAuthError(error)) {
              setUploadStatus({ authError: error.response?.data })
            } else if (error) {
              setUploadStatus({ error })
            } else if (data) {
              setUploadStatus({
                importedGuests: data.guests_imported,
                rowErrors: data.errors,
              })
            }
          },
        }
      )
    }
  }

  return (
    <DragDropBox
      className={className}
      filePicked={!!file}
      onDragEnter={dragTargetForFiles}
      onDragOver={dragTargetForFiles}
      onDrop={handleDrop}
      onClick={() => !file && inputFile.current?.click()}
    >
      <IconBox>
        <FileUpload width={24} />
        <MText variant="body">
          <span>Drop file or </span>
          <FilePickerLink
            onClick={(e) => {
              e.stopPropagation()
              inputFile.current?.click()
            }}
          >
            open file browser
          </FilePickerLink>
        </MText>
        {file && (
          <>
            <span>{`"${message}" ready for import`}</span>
            <UploadButton
              onClick={handleOnClick}
              loading={postGuestsCSV.isLoading}
            >
              Import file
            </UploadButton>
          </>
        )}
      </IconBox>
      <input
        type="file"
        id="file"
        ref={inputFile}
        style={{ display: "none" }}
        onChange={handleOnChange}
        onClick={(e) => {
          // Makes it possible to upload the same file multiple times.
          e.currentTarget.value = ""
        }}
      />
    </DragDropBox>
  )
}

export interface UploadStatus {
  importedGuests?: TPostGuestsCSVResponse["guests_imported"]
  rowErrors?: TPostGuestsCSVRowError["errors"]
  authError?: TPostGuestsCSVAuthError
  error?: AxiosError
}

// 5mb
const FILE_SIZE_LIMIT = 5000000

function handleFile(
  files: FileList | null,
  setText: (message: string) => void,
  setFilePicked: (file: File) => void
) {
  if (!files) {
    setText("no file found")
    return
  }

  if (files.length > 1) {
    setText("only upload one file")
    return
  }

  const file = files[0]

  if (file.size > FILE_SIZE_LIMIT) {
    setText("file to large")
    return
  }
  setText(`${file.name}`)
  setFilePicked(file)
}

const FilePickerLink = styled(Link)`
  cursor: pointer;
`

const UploadButton = styled(MButtonLegacy)`
  z-index: 99;
`

const IconBox = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: ${spacing.XS};
`

const DragDropBox = styled.div<{ filePicked: boolean }>`
  display: flex;
  justify-content: center;
  ${({ filePicked }) => !filePicked && "cursor: pointer;"}
  align-items: center;
  height: 20vh;
  max-height: 350px;
  border: 2px dashed ${mColors.divider};
  background: ${greyScale[50]}90;
  border-radius: 6px;
`
